<template>
<div class="pt-5  px-4">
<v-form ref="form" v-model="valid">
  <v-row class="pb-5">
    <v-col cols="12" lg="4" md="4" sm="3">
      <v-text-field outlined dense hide-details label="Name" v-model="userObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
    </v-col>
    <v-col cols="12" lg="4" md="4" sm="3">
      <v-text-field outlined dense hide-details label="Email" v-model="userObj.email" :rules="setValidation('emailValidation')"></v-text-field>
    </v-col>
    <v-col cols="12" lg="4" md="4" sm="3">
      <v-text-field outlined dense hide-details label="Phone" v-model="userObj.phone"></v-text-field>
    </v-col>
    <v-col cols="12" lg="4" md="4" sm="3">
      <v-text-field outlined dense hide-details label="Course" v-model="userObj.course" :rules="setValidation('requiredValidation')"></v-text-field>
    </v-col>
    <v-col cols="12" lg="4" md="4" sm="3">
      <v-text-field outlined dense hide-details label="Status" v-model="userObj.status" :rules="setValidation('requiredValidation')"></v-text-field>
    </v-col>
  </v-row>
  <v-btn :disabled="!valid" color="success" class="mr-4" @click="SaveUpdateHandler"> {{isEdit ? 'Update': 'Save'}} </v-btn>
  <v-btn color="error" class="mr-4"  @click="$router.push(`/admissions`)"> Cancel </v-btn>
  </v-form>
</div>
</template>

<script>
export default {
  data () {
    return {
      valid: false,
      userObj: {},
      isEdit: this.$route.params.id === 'new' ? false : true
    }
  },
  mounted () {
    if (this.isEdit) this.getSingleRecordHandler(this.$route.params.id)
  },
  methods: {
    SaveUpdateHandler() {
      if (!this.isEdit) this.$_execute('post', 'admissions', this.userObj)
      else this.$_execute('put', `admissions/${this.$route.params.id}`, this.userObj)
      this.$root.$emit('snackbar', { message: this.isEdit ? 'Updated Successfully' : 'Created Successfully', color: 'success' })
      this.$router.push(`/admissions`)
    },
    getSingleRecordHandler(id) {
      this.$_execute('get', `admissions/${id}`).then(response => {
        this.userObj = response.data
      })
    }
  }
}
</script>

<style>

</style>